<template>
  <section class="page-heading">
    <div
    class="page-heading__bg"
    :class="{'page-heading__bg--cut': cut}"
    >
      <img
        class="page-heading__hero"
        :class="{'hero-full': cut}"
        :src="src"
        :alt="title"
      >
      <div v-if="!cut" class="gradient-bg"></div>
    </div>
    <div
    class="page-heading__titlegroup"
    :class="{'big': cut}">
      <bxs-layout>
        <bxs-row>
          <bxs-col
          cols="12"
          ds="12"
          >
            <h1 ref="title">{{ title }}</h1>
            <h2 ref="subtitle">{{ subtitle }}</h2>
            <div v-if="btn">
              <bxs-btn
              to="/chi-siamo"
              append-icon="arrow-right">Scopri di più</bxs-btn>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'bxs-page-heading',
    props: {
        title: {
            type: String,
            required: true,
            default: 'Titolo della pagina'
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        },
        src: {
            type: String,
            required: false,
            default: ''
        },
        cut: {
            type: Boolean,
            required: false,
            default: false
        },
        btn: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false
        }

    },

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })

          titleMotion
          .fromTo(this.$refs.subtitle, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';
.page-heading{
  padding: 0 !important;
  overflow: hidden;

  &__hero{
    object-fit: cover;
    -webkit-object-fit: cover;
    position: relative;
    max-height: 67vh;
    height: 51vh;

    &.hero-full{
      height: 67vh;

      @include breakpoint(tablet_landscape) {
        object-position: center right;
      }

      @include breakpoint(tablet_portrait) {
        height: 59vh;
      }

      @include breakpoint(mobile) {
        height: 52vh;
      }
    }
  }

  .gradient-bg{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #06868B -30%, rgba(6, 134, 139, 0));
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__bg{
    margin-bottom: var(--distance-vertical-d);
    position: relative;

    @include breakpoint(tablet_portrait){
      margin-bottom: 30px;
    }

    &--cut{
      border-radius: 0 0 0 20vw;
      overflow: hidden;

      @include breakpoint(low_height) {
        border-radius: 0;
      }

      @include breakpoint(desktop) {
        border-radius: 0;
      }
    }
  }
  &__titlegroup{
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    width: 1200px;
    padding: 100px 0 0;

    @include breakpoint(desktop) {
      transform: translate(-50vw, 0);
    }

    @include breakpoint(tablet_portrait) {
      padding-top: 60px;
      max-width: 140vw;
    }

    @include breakpoint(mobile) {
      padding-top: 40px;
    }

    h1{
      letter-spacing: 0.005em;
      opacity: 0;
      margin-bottom: 0;
      color: white;
    }

    h2{
      opacity: 0;
      display: inline-block;
      font-weight: normal;
      color: white;
    }

    h1{
      padding-right: 250px;
      font-size: 20px;
      margin-bottom: 20px;

      @include breakpoint(desktop) {
        padding-right: 0;
      }
    }

    h2{
      font-weight: 700;
      font-size: 60px;
      line-height: 88px;
      max-width: 1040px;

      @include breakpoint(fullscreen) {
        font-size: 50px;
        line-height: 70px;
      }

      @include breakpoint(desktop) {
        font-size: 42px;
        line-height: 60px;
        padding-right: 100px;
      }

      @include breakpoint(tablet_landscape) {
        padding-right: 40vw;
      }

      @include breakpoint(tablet_landscape) {
        font-size: 32px;
        line-height: 50px;
      }
    }

    &.big{
      max-width: max-content !important;

      h1{
        padding-right: 250px;
        font-size: $h1;
        line-height: $h1-lh;
        margin: 0;

        @include breakpoint(desktop) {
          padding-right: 30vw;
          font-size: 60px;
          line-height: 68px;
        }

        @include breakpoint(tablet_landscape) {
          padding-right: 0;
          max-width: 60%;
        }

        @include breakpoint(tablet_portrait) {
          font-size: 46px;
          line-height: 50px;
          max-width: 40%;
        }

        @include breakpoint(mobile) {
          font-size: 36px;
          line-height: 32px;
          max-width: 30%;
        }
      }

      h2{
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        margin-top: 20px;
        max-width: 560px;
        padding-right: 0 !important;

        @include breakpoint(tablet_portrait) {
          font-size: 18px;
          line-height: 36px;
        }

        @include breakpoint(mobile) {
          max-width: 30%;
          line-height: 26px;
          margin: 40px 0;
        }
      }
    }
  }
  .figure-wrapper{
      border: 1px solid var(--color-lightgrey);
      opacity: 0;
    }
}
</style>
