<template>
  <section class="we-numbers">
    <bxs-layout class="bxs-layout-fluid">
      <bxs-row>
        <bxs-col col="8">
          <div class="we-numbers-block">
            <div class="we-numbers-left">
              <h3 class="mini" ref="subtitle">We.Numbers</h3>
              <h3 class="big" ref="title">10 anni di Musica</h3>
              <p>Il nostro ensemble è nato 10 anni fa come Ensemble Testori, di impronta classica.
                Un progetto che si è trasformato nella solida realtà che oggi è We.Ensemble</p>
              <img class="mobile-image" src="/img/we-numbers.png" alt="">
              <div class="three-numbers">
                <div class="numbers-element">
                  <div class="number">50<span>+</span></div>
                  <div class="number-label">Musicisti professionisti</div>
                </div>
                <div class="numbers-element">
                  <div class="number">100<span>+</span></div>
                  <div class="number-label">Repertorio Canzoni</div>
                </div>
                <div class="numbers-element">
                  <div class="number">200<span>+</span></div>
                  <div class="number-label">Clienti soddisfatti</div>
                </div>
              </div>
            </div>
            <div class="we-numbers-right">
              <img src="/img/we-numbers.png" alt="">
            </div>
          </div>
        </bxs-col>
      </bxs-row>
    </bxs-layout>
  </section>
</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'bxs-we-numbers',

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })

          titleMotion
          .fromTo(this.$refs.subtitle, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.we-numbers{
  color: white;
  margin-bottom: 130px;

  @include breakpoint(tablet_portrait) {
    margin-bottom: 40px;
  }

  .we-numbers-block{
    display: flex;
    margin: 0 auto;
    padding: 40px 60px;
    max-width: 1330px;
    background: url('@/assets/img/bg-numbers.png') no-repeat center center;
    background-size: cover;
    border-radius: 36px;
    min-height: 576px;

    @include breakpoint(mobile) {
      padding: 20px 30px;
    }

    .we-numbers-left{
      width: 50%;

      @include breakpoint(tablet_landscape) {
        width: 100%;
      }

      .mobile-image{
        display: none;

        @include breakpoint(tablet_landscape) {
          display: block;
          max-width: 500px;
          margin: 0 auto 40px;
        }

        @include breakpoint(mobile) {
          margin-bottom: 20px;
        }
      }
    }

    .we-numbers-right{
      width: 50%;

      @include breakpoint(tablet_landscape) {
        display: none;
      }
    }

    .mini{
      margin-top: 40px;
      margin-bottom: 40px;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: .1em;
      font-weight: 700;
    }

    .big{
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      padding-bottom: 10px;
    }

    p{
      opacity: .5;
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 40px;
      padding-right: 60px;

      @include breakpoint(mobile) {
        padding-right: 0;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .three-numbers{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      @include breakpoint(tablet_portrait) {
        margin-bottom: 40px;
      }

      .numbers-element{

        @include breakpoint(tablet_portrait) {
          flex: 1 0 33%;
        }

        .number{
          font-size: 48px;
          line-height: 77px;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;

          @include breakpoint(mobile) {
            font-size: 40px;
          }
        }

        .number-label{
          font-weight: 600;
          opacity: .5;
          line-height: 30px;
          max-width: 80%;

          @include breakpoint(mobile) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
