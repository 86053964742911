<template>
  <div class="about">
    <bxs-page-heading
      title="About Us"
      subtitle="Un ensemble unito dalla missione di rendere ogni performance indimenticabile."
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-us.png"
    >
    </bxs-page-heading>
    <section class="about-focus">
      <bxs-layout>
        <bxs-row style="margin-bottom: 80px">
          <bxs-col ms="12" dm="7" cols="7">
            <div class="text-content">
              <h2>We.Mission</h2>
              <p>
                <strong>Vogliamo semplificare il lavoro di chiunque abbia bisogno di musica e di musicisti nella propria realtà.</strong>
              </p>
              <p>
                Uno dei punti di forza del progetto è quello di soddisfare le richieste di una vasta gamma di clienti, agevolando i processi lavorativi, burocratici e comunicativi.
              </p>
            </div>
          </bxs-col>
          <bxs-col cols="5" class="bxs-col-hide-mobile">
            <bxs-figure class="main-focus-img" src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-1.jpg" alt="I migliori partner musicali in Italia." height="435"></bxs-figure>
          </bxs-col>
        </bxs-row>
        <bxs-row style="margin-bottom: 80px">
          <bxs-col cols="5" class="bxs-col-hide-mobile">
            <bxs-figure class="main-focus-img" src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/about-2.jpg" alt="I migliori partner musicali in Italia." height="435"></bxs-figure>
          </bxs-col>
          <bxs-col ms="12" dm="7" cols="7">
            <div class="text-content text-content-left">
              <h2>We.Mission</h2>
              <p>
                <strong>Produrre musica moderna e pop per qualsiasi tipo di evento e live show con strumenti classici.</strong>
              </p>
              <p>
                A distanza di 10 anni dall’inizio del progetto, We.Ensemble si presenta come una realtà, in grado di produrre musica di qualità  sopraffina.
              </p>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="we-people">
      <div class="people-titlegroup">
        <h2>We.People</h2>
        <p>Il gruppo ha lavorato per e con Netflix, BMW, Gucci, Hugo Boss, Geox, Etro, collabora su base settimanale con Fever per i Concerti Candlelight, ha inciso per artisti come Madame, Mengoni, i Baustelle e ha preso parte a puntate televisive e concerti live con Cesare Cremonini, i Pooh, Enrico Ruggeri, Dardust e molti altri.</p>
        <p>L’Ensemble vanta un’esperienza internazionale con produzioni estere tra le quali una collaborazione con l’Ambasciata Italiana a Parigi per Expo 2030 e un live concert in Arabia Saudita per Dardust, esibendosi anche in location prestigiose come il Teatro San Babila, il Teatro degli Arcimboldi, La Palazzina Liberty, il palco estivo del Castello Sforzesco di Milano e molte altre. Il nostro progetto è basato sull’esperienza e sulla professionalità di musicisti e specialisti del settore che lavorano in questo campo quotidianamente.</p>
      </div>
      <ul class="people-grid">
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/giulia-sandoli.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Giulia Sandoli</h3>
            <div>Direttrice Generale</div>
            <a href="https://www.linkedin.com/in/giulia-sandoli/" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/susanna-tognella2.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Susanna Tognella</h3>
            <div>Responsabile area amministrativa</div>
            <a href="https://www.linkedin.com/in/susanna-tognella/" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/martina-motta2.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Martina Motta</h3>
            <div>Responsabile di produzione</div>
            <a href="https://www.linkedin.com/in/martina-motta-we/" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/luca-rapazzini2.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Luca Rapazzini</h3>
            <div>Direttore Artistico</div>
            <!-- <a href="#" target="_blank"><bxs-icon name="li"></bxs-icon></a>-->
          </div>
        </li>
        <li>
          <div class="figure-wrapper">
            <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/09/sara-marazzi.png" height="400" width="400"></bxs-figure>
          </div>
          <div class="people-meta">
            <h3>Sara Marazzi</h3>
            <div>Consulente amministrativo</div>
            <a href="https://www.linkedin.com/in/sara-marazzi-747184164/" target="_blank"><bxs-icon name="li"></bxs-icon></a>
          </div>
        </li>
      </ul>
    </section>

    <bxs-we-numbers style="margin-bottom: 0 !important;"/>

  </div>
</template>

<script>

export default {
  name: 'about',
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.about-focus{
  margin-top: 100px;

  @include breakpoint(mobile) {
    margin-top: 60px;
  }
}

.bxs-col-hide-mobile{
  @include breakpoint(tablet_landscape) {
    display: none !important;
  }
}

h2{
  font-size: 48px;
  line-height: 60px;
  color: var(--color-black);
  letter-spacing: .03em;
}
.text-content{
  margin-top: 70px;
  padding-right: 40px;

  @include breakpoint(tablet_landscape) {
    margin-top: -40px;
  }

  &.text-content-left{
    padding-left: 50px;

    @include breakpoint(tablet_landscape) {
      padding-left: 0;
      margin-bottom: -40px !important;
    }
  }
}
.main-focus-img{
  border-radius: 50px !important;

  @include breakpoint(desktop){
    height: auto !important;
  }
}

p{
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;

  strong{
    font-weight: 600;
  }
}

.we-people{
  background: var(--color-secondary);
  text-align: center;
  padding-top: 80px;
  padding-bottom: 20px;
  margin-bottom: 130px;

  @include breakpoint(mobile) {
    margin-bottom: 60px;
  }

  .people-titlegroup{
    padding-bottom: 80px;

    @include breakpoint(fullscreen) {
      padding-left: 40px;
      padding-right: 40px;
    }

    p{
      font-size: 24px;
      line-height: 38px;
      opacity: .7;
      max-width: 1356px;
      margin: 0 auto;

      @include breakpoint(tablet_landscape) {
        font-size: 20px;
        line-height: 32px;
      }

      &:nth-child(2){
        margin-bottom: 40px;
      }
    }
  }

  .people-grid{
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(mobile) {
      padding-bottom: 80px;
      margin-top: -60px;
    }

    li{
      flex: 1 1 33.33%;
      margin-bottom: 100px;

      @include breakpoint(tablet_portrait) {
        margin-bottom: 50px;
      }

      @include breakpoint(mobile) {
        transform: scale(0.8);
        margin-bottom: -70px;
      }
    }

    .figure-wrapper{
      display: inline-block;
      position: relative;

      &::after{
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        background: white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translate(0, 50px);
      }
    }

    figure{
      background: transparent;
      overflow: visible;
      z-index: 10;
    }

    .people-meta{
      margin-top: 80px;

      h3{
        font-size: 22px;
        line-height: 22px;
        color: var(--color-black);
      }

      div{
        font-size: 20px;
        opacity: .7;
        margin-top: -10px;
        margin-bottom: 20px;
      }

      a{
        @include Transition;
        color: var(--color-black);
        opacity: .7;
        transform: scale(1.4);

        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
</style>
