<template>
    <div class="bxs-footer">
      <bxs-layout>
          <bxs-row>
            <bxs-col cols="4">
              <router-link
              to="/"
              class="bxs-logo logo"
              style="max-width: 150px;">
                  <img src="/img/logo-footer.png">
              </router-link>
              <div class="claim">La musica perfetta per il tuo evento.</div>
              <ul class="social-links">
                <li class="link-ig"><a href="https://www.instagram.com/we.ensembleofficial" target="_blank"><img src="/img/ig.png" alt="Instagram"></a></li>
                <li class="link-fb"><a href="https://www.facebook.com/ensembletestori/?locale=it_IT" target="_blank"><img src="/img/fb.png" alt="Facebook"></a></li>
                <!--
                  <li class="link-tt"><a href="#"><img src="/img/tiktok.png" alt="Tik Tok" target="_blank"></a></li>
                  <li class="link-in"><a href="#"><img src="/img/in.png" alt="LinkedIn" target="_blank"></a></li>
                -->
              </ul>
            </bxs-col>
            <bxs-col cols="2">
              <h6>Pagine</h6>
              <ul class="footer-links">
                <li>
                    <router-link to="/chi-siamo">About Us</router-link>
                </li>
                <li>
                  <router-link to="/servizi">Servizi</router-link>
                </li>
                <li>
                  <router-link to="/progetti">Progetti</router-link>
                </li>
                <li>
                    <router-link to="/contatti">Contact</router-link>
                </li>
              </ul>
            </bxs-col>
            <bxs-col cols="2">
              <h6>Servizi</h6>
              <ul class="footer-links">
                <li>
                  Live Show
                </li>
                <li>
                    Arrangiamento
                </li>
                <li>
                  Consulenza
                </li>
                <li>
                  Registrazione studio
                </li>
              </ul>
            </bxs-col>
            <bxs-col cols="4">
              <h6>Contatti</h6>
              <ul class="address-block">
                <li>
                  <address>Via A. Gramsci, 5 <br>
                    20026 Novate Milanese (MI)
                  </address>
                </li>
                <li>
                  <a href="mailto:amministrazione@weplaycoop.net">amministrazione@weplaycoop.net</a>
                </li>
                <li>
                  <a href="tel:+393335280531">+39 333 5280531</a>
                </li>
              </ul>
            </bxs-col>
          </bxs-row>
      </bxs-layout>

      <bxs-divider></bxs-divider>

      <bxs-layout>
          <bxs-row no-gutters>
              <bxs-col cols="12" class="copyright">
                We.Ensemble | Un progetto di We.Play Coop
              </bxs-col>
          </bxs-row>
      </bxs-layout>
    </div>
</template>

<script>
export default {
    name: 'bxs_footer'
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.bxs-footer{
  position: relative;
  width: 100%;
  min-height: var(--footer-min-height);
  background-color: var(--footer-background-color);
  color: var(--footer-color);
  z-index: var(--layer-navbar);
  transition: transform 0.5s ease-out;

  .claim{
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .bxs-layout{
    max-width: 1330px;
  }

  h6{
    font-size: 25px;
    font-weight: 700;
    color: var(--color-black);

    @include breakpoint(mobile) {
      margin-bottom: 0;
    }
  }

  .social-links{
    display: flex;

    li{
      margin-right: 10%;
    }
  }

  .footer-links{
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    @include breakpoint(mobile) {
      margin-top: 20px;
    }

    li{
      margin-bottom: 40px;

      @include breakpoint(mobile){
        margin-bottom: 15px !important;
      }

      a{
        @include Transition;
        text-decoration: none;
        position: relative;
        outline: none !important;

        &::after{
          @include Transition;
          content: "";
          background-color: black;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0;
          transition: 0.2s;
        }

        &:hover{
          color: var(--color-primary);
        }

        &:hover::after{
          background-color: var(--color-primary);
          width: 100%;
        }

        &.router-link-active::after{
          background-color: black !important;
        }
      }
    }
  }

  .address-block{
    address{
      margin-top: 40px;
    }

    li:nth-child(2){
      margin-top: 15px;
    }

    li:nth-child(3){
      margin-top: 41px;
    }

    a{
      @include Transition;
      text-decoration: none;

      &:hover{
        color: var(--color-primary);
      }
    }
  }

  .bxs-layout .bxs-row .bxs-col{
    @include breakpoint(tablet_landscape) {
      width: 50% !important;
      max-width: 50% !important;
      flex: 0 0 50% !important;
    }

    &:nth-child(1), &:nth-child(4){
      @include breakpoint(mobile) {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }

  .copyright.bxs-col.bxs-col-12{
    text-align: center;
    font-size: 18px;
    color: #4D5053;
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;

    @include breakpoint(mobile) {
      font-size: 16px;
    }
  }
}
</style>
