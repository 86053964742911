<template>
  <div class="projects">
    <bxs-page-heading
      title="Progetti"
      subtitle="Un ensemble di progetti unici che celebrano la diversità della musica."
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/projects.jpeg"
    >
    </bxs-page-heading>

    <section class="projects-focus">
      <bxs-layout>
        <bxs-row>
          <bxs-col ms="12" dm="5" cols="5">
            <h2>Il nostro team di esperti musicisti e professionisti è pronto a supportarti in ogni fase del tuo percorso.</h2>
          </bxs-col>
          <bxs-col ms="12" dm="7" cols="7">
            <p class="p-1">Il portfolio aggiornato di We.Ensemble, un insieme eterogeneo di eventi, live show, concerti e molto altro. </p>
            <p class="p-2">La profondità del nostro servizio al fine di soddisfare tutte le richieste musicali dell’azienda committente. Consulenza, arrangiamento, esecuzione musicale, feedback post evento.
            </p>
            <p class="p-3">Il nostro repertorio conta più di mille arrangiamenti originali, contenente musica pop italiana e internazionale.</p>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="counters">
      <bxs-layout class="bxs-layout-fluid">
        <div class="counters-content">
          <div class="counter">
            <div class="number">
              <strong>100</strong><span>%</span>
            </div>
            <div class="number-info">
              <strong>Personalizzazione completa:</strong>
              <span>Adattabilità musicale garantita.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>50</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Musicisti professionisti:</strong>
              <span>Roaster profondo, per ogni tipo di evento.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>100</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Repertorio Canzoni:</strong>
              <span>Con arrangiamenti dedicati.</span>
            </div>
          </div>
          <div class="counter">
            <div class="number">
              <strong>200</strong><span>+</span>
            </div>
            <div class="number-info">
              <strong>Clienti soddisfatti:</strong>
              <span>La nostra musica, il vostro evento.</span>
            </div>
          </div>
        </div>
      </bxs-layout>
    </section>

    <section v-if="posts" class="projects-wrapper">
      <bxs-layout>
        <bxs-row v-for="(post, i) in posts" :key="i" :class="`post-${i}`">
          <bxs-col cols="12">
            <router-link :to="`/progetti/${post.slug}`" class="project-link">
              <div class="single-project">
                <div class="single-project-image">
                  <bxs-figure v-if="post.yoast_head_json.og_image" :src="post.yoast_head_json.og_image[0].url" width="340" height="340" />
                  <bxs-figure v-else src="/img/post-placeholder.png" width="340" height="340" />
                </div>
                <div class="single-project-content">
                  <h3>{{ post.title.rendered }}</h3>
                  <div class="single-project-image mobile-img">
                    <bxs-figure v-if="post.yoast_head_json.og_image" :src="post.yoast_head_json.og_image[0].url" width="340" height="340" />
                    <bxs-figure v-else src="/img/post-placeholder.png" width="340" height="340" />
                  </div>
                  <p>{{ post.acf.project_short_desc }}</p>
                  <bxs-btn
                  append-icon="arrow-right"
                  :to="`/progetti/${post.slug}`"
                  class="simple-link"
                  >
                    Scopri di più
                  </bxs-btn>
                </div>
              </div>
            </router-link>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <!---------****************--------->

    <bxs-our-customers/>

    <section class="book-consulences">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="6" class="bxs-col-cons-img">
            <bxs-figure src="/img/consulenza.jpg">
            </bxs-figure>
          </bxs-col>
          <bxs-col cols="6" class="bxs-col-cons-focus">
            <div class="consulences-content">
              <h3>Prenota una consulenza per il tuo evento.</h3>
              <p>I nostri professionisti sono pronti ad accogliere le tue richieste musicali per elevare il prestigio della tua iniziativa.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/contatti"
              class="inverted"
              >
                Contattaci
              </bxs-btn>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'projects',
  mounted () {
    this.$nextTick(this.start)
  },
  computed: {
    ...mapState({
      posts: state => state.news
    })
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.projects-wrapper{
  margin-top: 100px;
  margin-bottom: 60px;

  @include breakpoint(mobile){
    margin-top: 40px;
  }

  .project-link{
    text-decoration: none !important;

    &:hover{

      .single-project{
        background-color: #CDFAFB !important;

        @include breakpoint(mobile){
          background: transparent !important;
        }
      }

      img{
        transform: scale(1.05);
      }
      .bxs-btn.simple-link .bxs-icon {
        transform: translate(2px, 0);
      }
    }
  }

  .single-project{
    @include Transition;
    background: #F0FDFE;
    border-radius: 60px;
    padding: 40px 40px;
    color: var(--color-black);
    display: flex;
    text-decoration: none !important;

    @include breakpoint(mobile){
      padding: 0;
      background: transparent !important;
    }

    figure{
      border-radius: 20px;
    }

    *, &:hover{
      text-decoration: none !important;
    }

    img{
      @include Transition;
      object-fit: cover;
      width: 340px;
      height: 340px;
      backface-visibility: hidden;
      will-change: transform;
    }

    .single-project-content{

      h3{
        margin-top: 30px;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 30px;

        @include breakpoint(mobile){
          margin-top: 0;
          font-size: 32px;
        }
      }

      p{
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 20px;

        @include breakpoint(mobile){
          margin-top: 0;
          font-size: 18px;
          line-height: 26px;
        }
      }

      .bxs-btn{
        margin-bottom: 20px;
      }

    }
  }

  .bxs-row:nth-child(even) .single-project{
    background: #f8f8f8;
  }

  .single-project-image{
    padding-right: 60px;

    @include breakpoint(tablet_portrait){
      display: none;
      padding-right: 0;
    }

    &.mobile-img{
      display: none;

      @include breakpoint(tablet_portrait){
        display: block;
        margin-bottom: 40px;
      }

      figure{
        @include breakpoint(tablet_portrait){
          width: 100% !important;
        }

        img{
          @include breakpoint(tablet_portrait){
            width: 100% !important;
          }
        }
      }
    }
  }

  .gallery{
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    align-content: space-between;

    figure{
      border-radius: 40px;
      margin: auto;
    }

    *{
      background: transparent;
    }
  }
}

.projects-focus{
  margin-top: 50px;
  margin-bottom: 50px;

  h2{
    color: var(--color-black);
    font-weight: 800;
    font-size: 44px;
    line-height: 64px;

    @include breakpoint(mobile){
      font-size: 32px;
      line-height: 40px;
      margin-top: -30px;
    }
  }

  p{
    font-size: 24px;
    line-height: 38px;
    padding-left: 24px;

    @include breakpoint(tablet_landscape){
      padding-left: 0;
    }
  }

  .p-1{
    font-weight: bold;
    color: var(--color-primary);
    margin-top: 20px;
  }

  .p-2{
    margin-top: 46px;
  }

  .p-3{
    margin-top: 46px;
  }
}

.counters{
  background-color: #E6FDFD;
  padding: 120px 0 120px;

  @include breakpoint(mobile){
    padding: 60px 0;
  }

  .counters-content{
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;

    @include breakpoint(tablet_landscape){
      padding: 0;
    }

    @include breakpoint(mobile){
      flex-direction: column;
    }

    .counter{
      width: 25%;

      @include breakpoint(mobile){
        width: 100%;
      }
    }

    .number{
      font-weight: 600;
      font-size: 80px;
      line-height: 100px;
      color: #024345;

      @include breakpoint(tablet_landscape){
        font-size: 50px;
      }
    }

    .number-info{
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      flex-direction: column;

      @include breakpoint(tablet_portrait){
        font-size: 18px;
      }

      @include breakpoint(mobile){
        font-size: 20px;
      }
    }
  }
}

.book-consulences{

  @include breakpoint(tablet_portrait){
    margin-bottom: -40px;
  }

  figure{
    border-radius: 50px;
  }

  h3{
    color: var(--color-black);
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 20px;
  }

  p{
    font-size: 22px;
    line-height: 40px;
  }

  .consulences-content{
    padding-left: 40px;
  }
}

.bxs-col-cons-img{
  @include breakpoint(tablet_portrait){
    display: none;
  }
}

.bxs-col-cons-focus{
  @include breakpoint(tablet_portrait){
    width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .consulences-content{
    @include breakpoint(tablet_portrait){
      padding-left: 0 !important;
    }
  }
}
</style>
