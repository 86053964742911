<template>
  <div v-if="article" class="single-project">
    <bxs-page-heading
      title="Progetti"
      :subtitle="article.project_title"
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/servizi.jpg"
    >
    </bxs-page-heading>

    <section class="project-details" v-if="article.project_category && article.project_title && article.project_client && article.project_date && article.project_tags">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="5">
            <div class="details-block">
              <div class="details-content">
                <strong>Cliente</strong><span>{{ article.project_client }}</span>
                <strong>Categoria</strong><span>{{ article.project_category }}</span>
                <strong>Tags</strong><span>{{ article.project_tags }}</span>
                <strong>Date</strong><span>{{ article.project_date }}</span>
              </div>
            </div>
          </bxs-col>
          <bxs-col cols="7" class="bxs-col-details-col">
            <div class="project-text">
              <h2>{{ article.title }}</h2>
              <div class="project-content-text" v-html="article.content"></div>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="project-details" v-else>
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="12">
            <div class="project-text">
              <h2>{{ article.title }}</h2>
              <div class="project-content-text" v-html="article.content"></div>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="project-gallery">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="12">
            <div class="gallery-wrapper">
              <div class="main-photo" v-if="article.img_url">
                <bxs-figure :src="article.img_url"></bxs-figure>
              </div>
              <div class="subgallery" v-if="article.title === 'Viaggio in Italia'">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/viaggio-1.jpeg"></bxs-figure>
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/viaggio-2.jpeg"></bxs-figure>
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/viaggio-3.jpeg"></bxs-figure>
              </div>
              <div class="subgallery" v-if="article.title === 'Bridgerton'">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2020/11/bridgerton-1.png"></bxs-figure>
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2020/11/bridgerton-2.png"></bxs-figure>
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2020/11/bridgerton-3.png"></bxs-figure>
              </div>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="book-consulences back-to-projects">
      <bxs-layout>
        <bxs-row>
          <bxs-col cols="6" class="bxs-col-cons-img">
            <bxs-figure src="/img/consulenza.jpg">
            </bxs-figure>
          </bxs-col>
          <bxs-col cols="6" class="bxs-col-cons-focus">
            <div class="consulences-content">
              <h3>Progetti</h3>
              <p>Il nostro team di esperti musicisti e professionisti è pronto a supportarti in ogni fase del tuo percorso.</p>
              <bxs-btn
              append-icon="arrow-right"
              to="/progetti"
              class="inverted"
              >
                Torna ai progetti
              </bxs-btn>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>
  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'single-project',
  mounted () {
    this.$nextTick(this.start)
  },
  computed: {
      ...mapState({
          article: state => state.article
      })
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.project-details{
  margin: 190px 0 0;

  .bxs-col-details-col{
    @include breakpoint(tablet_portrait){
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  > .bxs-layout > .bxs-row{
    @include breakpoint(tablet_landscape){
      flex-direction: row-reverse;
    }
  }

  @include breakpoint(tablet_portrait){
    margin: 100px 0 0;
  }

  .details-block{
    background: #CDFAFB;
    border-radius: 50px;
    padding: 30px 40px;

    @include breakpoint(tablet_landscape){
      padding: 20px 10px;
      font-size: 16px;
    }

    @include breakpoint(tablet_portrait){
      display: none;
    }

    .details-content{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px 20px;
      padding: 20px;
    }
  }

  .project-text{
    margin-top: -85px;
    padding-left: 20px;

    @include breakpoint(tablet_portrait){
      padding-left: 0;
    }

    h2{
      @include breakpoint(tablet_portrait){
        font-size: 48px;
      }
    }

    .project-content-text{
      font-size: 22px;
      line-height: 33px;
      margin-bottom: 40px;
      color: #4D5053;
      padding-left: 5px;

      @include breakpoint(tablet_portrait){
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

.book-consulences{

  &.back-to-projects{
    @include breakpoint(tablet_portrait){
      margin-top: -80px;
    }
  }

  figure{
    border-radius: 50px;
  }

  h3{
    color: var(--color-black);
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 20px;
  }

  p{
    font-size: 22px;
    line-height: 40px;
  }

  .consulences-content{
    padding-left: 40px;
  }
}
.project-gallery{
  margin-bottom: 100px;
  margin-top: -50px;

  @include breakpoint(tablet_portrait){
    margin-bottom: 50px;
  }

  .main-photo{
    margin-bottom: 30px;

    img{
      max-height: 800px;
      object-fit: cover;
    }

    figure{
      border-radius: 70px;

      @include breakpoint(mobile){
        border-radius: 20px;
      }
    }
  }

  .subgallery{
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    gap: 30px;

    figure{
      flex-grow: 1;
      border-radius: 40px;
      width: 30%;

      @include breakpoint(tablet_portrait){
        width: 100%;
        border-radius: 20px;
      }

      img{
        width: 370px;
        object-fit: cover;
        height: 360px;

        @include breakpoint(tablet_portrait){
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
