<template>
  <div class="home">
    <bxs-page-heading
      title="La musica perfetta per il vostro evento."
      subtitle="We.Ensemble offre servizi musicali adatti a tutti i tipi di richieste, grazie ad un’esperienza decennale in Italia e all’estero."
      src="/img/hero-home.jpg"
      :cut="true"
      :btn="true"
      to="/chi-siamo"
    >
    </bxs-page-heading>
    <section class="main-focus">
      <bxs-layout>
        <bxs-row>
          <bxs-col ms="12" mm="5" col="5">
            <h2>I migliori partner musicali in Italia.</h2>
            <p>Scopri i musicisti di We.Ensemble, professionisti di formazione classica dal background poliedrico.</p>
            <!--
              <div class="contact-block">
                  <a href="tel:+393335280531" class="phone-hover">
                    <div class="phone-logo">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M32.0012 26.0749C30.7796 24.8442 27.8211 23.0482 26.3857 22.3243C24.5164 21.3828 24.3625 21.3059 22.8933 22.3974C21.9133 23.1258 21.2617 23.7766 20.1148 23.532C18.9678 23.2874 16.4754 21.9082 14.2931 19.7329C12.1107 17.5576 10.6514 14.9931 10.4061 13.8501C10.1607 12.7071 10.8222 12.0632 11.5438 11.081C12.5607 9.69642 12.4838 9.46566 11.6145 7.59651C10.9368 6.14273 9.08833 3.21208 7.85291 1.99675C6.53134 0.691421 6.53134 0.92218 5.67979 1.27601C4.98652 1.56768 4.32141 1.92222 3.69281 2.3352C2.46201 3.15285 1.77892 3.83206 1.30122 4.85278C0.823513 5.87351 0.608893 8.26648 3.07588 12.7478C5.54286 17.2292 7.27367 19.5206 10.8561 23.0928C14.4385 26.6649 17.1931 28.5856 21.2202 30.844C26.2018 33.6339 28.1126 33.09 29.1365 32.6131C30.1604 32.1362 30.8427 31.4593 31.662 30.2286C32.076 29.6011 32.4314 28.9368 32.7235 28.2441C33.0781 27.3957 33.3089 27.3957 32.0012 26.0749Z" stroke="#05858A" stroke-width="2" stroke-miterlimit="10"/>
                      </svg>
                    </div>
                  </a>
                  <div class="contact-block-content">
                    <a href="tel:+393335280531"><strong>+39 3335280531</strong></a>
                    <span>Contattaci per maggiori informazioni.</span>
                  </div>
              </div>
            -->
            <bxs-btn
            append-icon="arrow-right"
            to="/contatti"
            class="inverted"
            >
              Contatti
            </bxs-btn>
          </bxs-col>
          <bxs-col col="7">
            <bxs-figure class="main-focus-img" src="/img/home-focus.jpg" alt="I migliori partner musicali in Italia.">
            </bxs-figure>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <bxs-our-customers/>

    <bxs-we-numbers/>

    <bxs-latest-posts/>

    <bxs-contact-banner/>

  </div>
</template>

<script>

export default {
  name: 'home',
  mounted () {
    this.$nextTick(this.start)
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.main-focus{
  margin-top: 100px;
  margin-bottom: 130px;

  @include breakpoint(tablet_landscape){
    margin-top: 50px;
    margin-bottom: 80px;
  }

  @include breakpoint(mobile){
    margin-top: 20px;
    margin-bottom: 40px;
  }

  h2{
    font-size: 50px;
    line-height: 64px;
    letter-spacing: 0.03em;
    color: var(--color-black);
    margin-bottom: 30px;

    @include breakpoint(tablet_landscape){
      line-height: 52px;
    }

    @include breakpoint(tablet_portrait){
      font-size: 36px;
      line-height: 40px;
    }
  }

  p{
    font-size: 22px;
    line-height: 34px;
    color: #4D5053;
    padding-right: 80px;
    margin-bottom: 40px;

    @include breakpoint(tablet_portrait){
      padding-right: 20px;
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 32px;
    }
  }

  .contact-block{
    margin-top: 50px;
    display: flex;
    margin-bottom: 50px;
  }

  .phone-logo{
    width: 93px;
    height: 93px;
    border-radius: 50%;
    background: #CDFAFB4D;
    border: 1px solid var(--color-primary);
    @include Transition;

    svg{
      margin: 28px;
    }
  }

  .phone-hover{
    @include Transition;
    &:hover{
      .phone-logo{
        background: rgb(205 250 251 / 70%);
      }
    }
  }

  .contact-block-content{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-left: 20px;
    font-size: 24px;
    line-height: 36px;
    color: #4D5053;
    margin-top: 12px;

    a{
      @include Transition;
      text-decoration: none;
    }

    span{
      display: inline-block;
      transform: translate(0, -8px);
    }
  }
  .main-focus-img{
    border-radius: 0vw 50% 0% 20%;
  }
}
</style>
