<template>
  <section class="our-customers">
    <bxs-layout class="bxs-layout-fluid">
      <bxs-row>
        <div class="customers-intro">
          <h3 ref="title">Oltre 10 anni di esperienza, moltissimi partner.</h3>
          <p ref="subtitle">Nel corso degli anni abbiamo avuto il piacere di collaborare con molti dei brand e agenzie più famosi al mondo.</p>
        </div>
      </bxs-row>
      <bxs-row>
        <ul class="brands">
          <li class="netflix"><img src="/img/logos/netflix.png" alt="Netflix"></li>
          <li class="boss"><img src="/img/logos/boss.png" alt="Boss"></li>
          <li class="veralab"><img src="/img/logos/veralab.webp" alt="Vera lab"></li>
          <li class="etro"><img src="/img/logos/etro.png" alt="Etro"></li>
          <li class="huawei"><img src="/img/logos/huawei.png" alt="Huawei"></li>
          <li class="geox"><img src="/img/logos/geox.png" alt="Geox"></li>
          <li class="gucci"><img src="/img/logos/gucci.png" alt="Gucci"></li>
          <li class="bmw"><img src="/img/logos/bmw.png" alt="Bmw"></li>
          <li class="fever"><img src="/img/logos/fever.png" alt="Fever"></li>
          <li class="candlelight"><img src="/img/logos/candlelight.png" alt="Candlelight"></li>
        </ul>
      </bxs-row>
    </bxs-layout>
  </section>
</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'bxs-our-customers',

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.our-customers{
  background-color: var(--color-secondary);
  padding: 100px 0 80px;

  margin-bottom: 130px;

  @include breakpoint(tablet_portrait) {
    margin-bottom: 60px;
  }

  .customers-intro{
    max-width: 1080px;
    margin: 0 auto;
    text-align: center;

    h3{
      font-weight: 600;
      font-size: 40px;
    }

    p{
      font-size: 20px;
      line-height: 32px;
      padding: 0 40px;
      margin-bottom: 40px;
    }
  }

  .brands{
    margin: 0 auto;
    display: flex;
    align-content: space-between;

    @include breakpoint(tablet_landscape){
      flex-wrap: wrap;
    }

    li{
      flex: 1 1 0;
      margin: 0 20px;
      //background: green;

      @include breakpoint(tablet_landscape){
        flex: 1 1 15%;
      }

      img{
        width: 120px;
        height: 100px;
        object-fit: contain;
        // clip-path: polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%);
      }

      &.huawei{
        margin: 0 0 0 10px;
      }

      &.veralab{
        margin-left: 5px;
      }

      &.bmw{
        margin: 0 5px;
      }

      &.fever, &.bmw{
        img{
          height: 90px;
        }
      }

      &.netflix, &.boss{
        img{
          margin-top: 3px;
          margin-bottom: -3px;
        }
      }

      &.fever{
        margin-right: 0;
        img{
          width: 110px;
        }
      }
    }
  }
}
</style>
