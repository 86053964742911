<template>
  <section class="contact-banner">
    <bxs-layout>
      <bxs-row>
        <div class="book-appointment">
          <h4 ref="title">Prenota un appuntamento</h4>
          <p ref="subtitle">Contattaci gratuitamente per portare la nostra musica al tuo evento.</p>
          <bxs-btn append-icon="arrow-right" to="/contatti">Contatti</bxs-btn>
        </div>
      </bxs-row>
    </bxs-layout>
  </section>
</template>

<script>
import { gsap } from 'gsap'
export default {
    name: 'bxs-contact-banner',

    mounted() {
      this.startAnimation()
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    methods: {
      startAnimation() {
        const titleMotion = gsap.timeline({})
        titleMotion.delay(0.2)

        titleMotion
          .fromTo(this.$refs.title, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })

          titleMotion
          .fromTo(this.$refs.subtitle, {
              opacity: '0',
              y: 50
          }, {
              opacity: '1',
              y: 0,
              duration: 0.8,
              ease: 'power3.easeOut'
          })
      }
    }
}

</script>
<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.book-appointment{
  background: url('@/assets/img/bg-contact.png') no-repeat center center;
  width: 100%;
  border-radius: 40px;
  min-height: 400px;
  text-align: center;
  padding: 80px 40px;
  margin-top: 130px;

  @include breakpoint(tablet_portrait) {
    margin-top: 60px;
  }

  h4{
    color: white;
    font-weight: 600;
    font-size: 50px;

    @include breakpoint(mobile) {
      font-size: 42px;
    }
  }

  p{
    color: white;
    font-size: 22px;
    line-height: 33px;
    max-width: 450px;
    margin: 0 auto 30px;
  }
}
</style>
