<template>
  <div class="services">
    <bxs-page-heading
      title="Servizi"
      subtitle="Servizi musicali per creare l'atmosfera perfetta."
      src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/servizi.jpg"
    >
    </bxs-page-heading>
    <section class="services-focus">
      <bxs-layout class="bxs-layout-fluid">
        <bxs-row>
          <div class="title-group">
            <h2>Servizi offerti</h2>
            <p>Il nostro ensemble è in grado di trasformarsi, a seconda delle esigenze del <br> cliente e dell’evento trattato.</p>
          </div>
        </bxs-row>
        <bxs-row>
          <ul class="flipboxes">
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h3>Live Show</h3>
                  <img src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/live-show.png" alt="">
                </div>
                <div class="flip-card-back">
                  <h3>Live Show</h3>
                  <p>La nostra musica, il tuo evento dal vivo. Scegli tra le nostre proposte quella che più ti piace.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h3>Arrangiamento e orchestrazione</h3>
                  <img src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/arrangiamento.png" alt="">
                </div>
                <div class="flip-card-back">
                  <h3>Arrangiamento e orchestrazione</h3>
                  <p>Il nostro team artistico si occupa di arrangiare e orchestrare brani per svariate formazioni su richiesta della realtà committente.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h3>Consulenza</h3>
                  <img src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/consulenza.png" alt="">
                </div>
                <div class="flip-card-back">
                  <h3>Consulenza</h3>
                  <p>Programmazione, estetica, scelta del repertorio e molto altro. La cura dei singoli dettagli in accordo con il cliente.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <h3>Registrazione studio</h3>
                  <img src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/tegistrazione.png" alt="">
                </div>
                <div class="flip-card-back">
                  <h3>Registrazione studio</h3>
                  <p>Musicisti professionisti per registrazioni in studio, flessibili per qualsiasi genere musicale, dalla classica alla trap.</p>
                </div>
              </div>
            </li>
          </ul>
          <ul class="flipboxes flipboxes-mobile">
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-back">
                  <h3>Live Show</h3>
                  <p>La nostra musica, il tuo evento dal vivo. Scegli tra le nostre proposte quella che più ti piace.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-back">
                  <h3>Arrangiamento e orchestrazione</h3>
                  <p>Il nostro team artistico si occupa di arrangiare e orchestrare brani per svariate formazioni su richiesta della realtà committente.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card" data-flipped="false">
              <div class="flip-card-inner">
                <div class="flip-card-back">
                  <h3>Consulenza</h3>
                  <p>Programmazione, estetica, scelta del repertorio e molto altro. La cura dei singoli dettagli in accordo con il cliente.</p>
                </div>
              </div>
            </li>
            <li data-card="flipCard" class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-back">
                  <h3>Registrazione studio</h3>
                  <p>Musicisti professionisti per registrazioni in studio, flessibili per qualsiasi genere musicale, dalla classica alla trap.</p>
                </div>
              </div>
            </li>
          </ul>
        </bxs-row>
      </bxs-layout>
    </section>

    <section class="formation">
      <bxs-layout>
        <bxs-row>
          <div class="title-group">
            <h2>Le nostre formazioni</h2>
            <p>Il nostro ensemble è in grado di trasformarsi, a seconda delle <br> esigenze del cliente e dell’evento trattato.</p>
          </div>
        </bxs-row>
        <bxs-row>
          <swiper
            :slides-per-view="3"
            :modules="Modules"
            :navigation="{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            }"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            :breakpoints="{
              '0': {
                slidesPerView: '1',
                spaceBetween: 20,
              },
              '520': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '768': {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              '1024': {
                slidesPerView: 3,
                spaceBetween: 40,
              }
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <div class="slide-content">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/swipe2.png">
                  <div class="caption">Duo Elettrico</div>
                </bxs-figure>
                <div class="slide-title">La proposta in duo elettrico è più esplosiva, con musica coinvolgente da interno, per eventi come cerimonie.</div>
                <p class="slide-text">Violino e violoncello elettrci amplificati su basi per ogni tipo di evento.</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-content">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/swipe1.png">
                  <div class="caption">Quartetto / Quintetto D'archi</div>
                </bxs-figure>
                <div class="slide-title">La proposta in quartetto o quintetto d’archi è più intima e si adatta a situazioni e ambienti non eccessivamente dispersivi.</div>
                <p class="slide-text">Due violini, viola, violoncello e contrabbasso, a seconda delle necessità amplificati o amplificati su basi.</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-content">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/10/swipe3.png">
                  <div class="caption">Quintetto Fiati</div>
                </bxs-figure>
                <div class="slide-title">Formazione interamente composta da fiati per occasioni particolari con una sonorità eclettica, in grado di rompere le righe per eventi eleganti o retrò.</div>
                <p class="slide-text">Flauto, oboe, clarinetto, fagotto e corno.</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-content">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/quartetto-archi-voce.png">
                  <div class="caption">Orchestra da camera</div>
                </bxs-figure>
                <div class="slide-title">La proposta dell’orchestra da camera è perfetta per festival e palchi di grandi dimensioni permettendo ad un pubblico numeroso di godere dell’impatto sonoro che solo un ensemble di questo genere può produrre.</div>
                <p class="slide-text">Formazione da 15~20 elementi</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-content">
                <bxs-figure src="https://staging.weplaycoop.it/wp-content/uploads/2024/11/ladies-in-strings.png">
                  <div class="caption">Quartetto / Quintetto d'archi con voce</div>
                </bxs-figure>
                <div class="slide-title">Repertorio che spazia dal pop anni ‘90 alle sonorità più swing dell’america anni ‘30, con una accattivante voce femminile accompagnata da un quartetto d’archi amplificato.</div>
                <p class="slide-text">Due violini, viola, violoncello, contrabbasso e voce.</p>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev-unique">
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.48633 6.97461L6.94531 12.4336L6.41211 12.9922L0.394531 6.97461L6.41211 0.982422L6.94531 1.51562L1.48633 6.97461Z" fill="black"/>
            </svg>
          </div>
          <div class="swiper-button-next-unique">
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.60547 6.97461L0.587891 12.9922L0.0546875 12.4336L5.51367 6.97461L0.0546875 1.51562L0.587891 0.982422L6.60547 6.97461Z" fill="black"/>
            </svg>
          </div>
        </bxs-row>
      </bxs-layout>
    </section>

    <bxs-contact-banner />

  </div>
</template>

<script>
import { gsap } from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectFade, Navigation, Autoplay } from 'swiper/modules'

export default {
  name: 'services',
  data () {
    return {
      moving: false
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      Modules: [EffectFade, Navigation, Autoplay]
    }
  },
  mounted () {
    this.$nextTick(this.start)
    this.flipInit()
  },
  methods: {
    flipInit() {
      const els = this.$el.querySelectorAll('[data-card="flipCard"]')

      els.forEach(el => {
        el.addEventListener('mouseenter', () => this.flipCard(el))
        el.addEventListener('mouseleave', () => this.flipCard(el))
      })
    },

    flipCard(card) {
      if (!this.moving) {
        const isFlipped = card.getAttribute('data-flipped') === 'true'
        this.moving = true
        if (isFlipped) {
            gsap.to(card.querySelector('.flip-card-inner'), { duration: 0.8, rotationY: 0 })
            card.setAttribute('data-flipped', 'false')
        } else {
            gsap.to(card.querySelector('.flip-card-inner'), { duration: 0.5, rotationY: 180 })
            card.setAttribute('data-flipped', 'true')
        }
        this.moving = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.services-focus{
  margin: 100px auto 0;
  max-width: 1530px;

  @include breakpoint(mobile) {
    margin-top: 40px;
  }

  .title-group{
    text-align: center;
    margin: 0 auto;
    max-width: 766px;

    h2{
      font-size: 65px;
      line-height: 78px;
      color: var(--color-black);

      @include breakpoint(mobile) {
        font-size: 48px;
        line-height: 58px;
      }
    }

    p{
      font-size: 22px;
      color: #525560;
    }
  }

  .flipboxes{
    margin: 40px auto 150px;
    display: flex;
    gap: 10px;

    @include breakpoint(tablet_landscape) {
      display: none;
    }

    .flip-card {
      width: 23.654vw;
      max-width: 362px;
      height: 518px;
      perspective: 1000px;
    }

    .flip-card-inner {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
    }

    .flip-card-front, .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;
    }

    .flip-card-front {
      color: #333;

      h3{
        position: absolute;
        bottom: 40px;
        color: #fff;
        text-align: left;
        left: 40px;
        font-size: 30px;

        @include breakpoint(desktop) {
          font-size: 20px;
          line-height: 22px;
          bottom: 100px;
        }
      }
    }

    .flip-card-back {
      background: #F0FDFE;
      color: var(--color-black);
      transform: rotateY(180deg);
      border: 1px solid var(--color-black);
      padding: 20px;
      text-align: center;
    }

    h3{
      font-size: 25px;
      line-height: 32px;
      color: var(--color-black);
      margin-bottom: 30px;
    }

    p{
      font-size: 22px;
      line-height: 33px;
      color: #4D5053;
    }
  }

  .flipboxes.flipboxes-mobile{
    display: none;
    flex-wrap: wrap;
    gap: 2%;

    @include breakpoint(tablet_landscape) {
      display: flex !important;
    }

    .flip-card{
      flex:1 1 48%;
      max-width: 50%;
      height: 300px;

      @include breakpoint(tablet_portrait) {
        flex: 1 1 98%;
        max-width: 100%;
      }
    }

    .flip-card-inner{
      transform: rotateY(180deg) !important;
    }
  }
}
.formation{
  background: var(--color-secondary);
  padding: 50px 0 80px;

  .title-group{
    text-align: center;
    margin: 0 auto 50px;
    max-width: 766px;

    h2{
      font-size: 65px;
      line-height: 78px;
      color: var(--color-black);

      @include breakpoint(mobile) {
        font-size: 48px;
        line-height: 58px;
      }
    }

    p{
      font-size: 22px;
      color: #525560;

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }
  }

  .swiper-slide{
    overflow: hidden;
    border-radius: 30px;
  }

  .slide-content{
    padding: 20px 20px 30px 20px;
    @include Transition;
    background-image: linear-gradient(to left, #fff 100%, #fff 50%, #06afb5 100%);
    background-size: 200% auto;
    cursor: pointer;
    min-height: 500px;

    &:hover{
      background-image: linear-gradient(to left, #024648 0%, #06afb5 50%, #06afb5 100%);
      background-position: right center;

      .slide-title, .slide-text{
        color: white;
      }
    }

    figure{
      border-radius: 30px 30px 0 0;

      img{
        max-height: 270px;
        object-fit: cover;
      }

      .caption{
        position: absolute;
        left: 16px;
        bottom: 16px;
        background: #fff;
        font-size: 16px;
        color: #4D5053;
        letter-spacing: .03em;
        display: inline-block;
        padding: 10px;
        border-radius: 10px;
      }
    }

    .slide-title, .slide-text{
      @include Transition;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-black);
      padding: 0 2px;
      letter-spacing: .01em;
      margin-top: 12px;
    }

    .slide-text{
      margin-bottom: 0;
    }

    .slide-title{
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
}

// Swiper
.swiper-button-next-unique, .swiper-button-prev-unique{
  @include Transition;
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  background: white;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-top: 8%;
  cursor: pointer;
  text-align: center;

  @media all and (max-width: 1360px) {
    display: none !important;
  }

  &:hover{
    background: #efefef;
  }

  svg{
    display: inline-block;
    margin-top: 17px;
  }
}
.swiper-button-next-unique{
  right: -60px;
}
.swiper-button-prev-unique{
  left: -60px;
  svg{
    margin-right: 2px;
  }
}
</style>
