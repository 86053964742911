<template>
    <header class="navframe">
        <bxs-layout>
            <bxs-row>
                <bxs-col cols="4">
                    <!-- <bxs-logo /> -->
                    <router-link
                    to="/"
                    class="bxs-logo logo"
                    style="max-width: 90px;">
                        <img src="/img/logo.png">
                    </router-link>
                </bxs-col>

                <bxs-col cols="8" class="nav-centered">
                    <ul class="flex nowrap align-center justify-end nav-wrapper">
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/chi-siamo">About Us</router-link>
                        </li>
                        <li>
                          <router-link to="/servizi">Servizi</router-link>
                        </li>
                        <li>
                          <router-link to="/progetti">Progetti</router-link>
                        </li>
                        <li>
                            <router-link to="/contatti">Contact</router-link>
                        </li>
                        <li class="search-button">
                          <a href="#" @click.prevent>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g opacity="0.8">
                              <path d="M16.6837 16.6844L21.9994 22.0001M19.375 10.1875C19.375 15.2616 15.2616 19.375 10.1875 19.375C5.11338 19.375 1 15.2616 1 10.1875C1 5.11338 5.11338 1 10.1875 1C15.2616 1 19.375 5.11338 19.375 10.1875Z" stroke="#141414" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                            </svg>
                          </a>
                        </li>
                    </ul>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </header>
</template>

<script>
export default {
    name: 'bxs_navframe',
    props: {
        actived: Boolean,
        hidden: Boolean
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.navframe {
  position: relative;
  width: 100%;
  min-height: var(--navbar-min-height);
  background-color: var(--navbar-background-color);
  margin-bottom: var(--navbar-margin-bottom);
  z-index: var(--layer-navbar);

  .nav-centered{
    margin: auto 0;

    @include breakpoint(tablet_portrait) {
      display: none;
    }
  }

  .nav-wrapper{

    li{
      margin-right: 2.2rem;

      &.search-button{
        transform: translate(0, 2px);
        //TEMP
        display: none;

        a{
          @include Transition;

          &:hover{
            transform: scale(1.1);
          }

          &::after{
            display: none !important;
          }
        }
      }
    }

    a{
      @include Transition;
      text-decoration: none;
      position: relative;
      outline: none !important;

      &::after{
        @include Transition;
        content: "";
        background-color: black;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        transition: 0.2s;
      }

      &:hover{
        color: var(--color-primary);
      }

      &:hover::after{
        background-color: var(--color-primary);
        width: 100%;
      }

      &.router-link-active::after{
        background-color: black !important;
      }
    }
  }
}
</style>
